import YouUseIntegration from './YouUseIntegration'

import './style.scss'

export default function Form() {
  return (
    <main>
      <section className="bodyForm">
        <h2 className="bodyTitleForm">
          Queremos saber um pouco mais sobre você!
        </h2>
        <section className="containerForms">
          <YouUseIntegration />
        </section>
      </section>
    </main>
  )
}
