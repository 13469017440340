import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Form from 'src/components/marketplace/TriagemFormSeller'

const desc =
  'Quer se tornar um parceiro Decathlon? Cadastre-se e venha vender no marketplace da maior loja de artigos esportivos!'

function Page() {
  return (
    <>
      <GatsbySeo title="Triagem | Decathlon" description={desc} />
      <Form />
    </>
  )
}

export default Page
