import { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { setStorage } from 'src/utils/storage'

import ModelModal from '../ModelModal'
import data from './data.json'

interface IntegrationOptionsProps {
  id: number
  integrationName: string
}

interface TriagemStep2Props {
  setMessageErrorReport: React.Dispatch<React.SetStateAction<string>>
}

export const contentModal = {
  title: 'O que é uma plataforma integradora?',
  description: `Um integrador serve para auxiliar o controle da sua loja e automatizar todos os processos de venda online em diversos marketplaces. 
          É possível gerenciar seus pedidos, estoque e todos os seus produtos nos diversos canais em um único local.
          `,
}

export default function TriagemStep2({
  setMessageErrorReport,
}: TriagemStep2Props) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showAnotherOption, setShowAnotherOption] = useState<boolean>(false)
  const [anotherOptionText, setAnotherOptionText] = useState<string>('')
  const [messageError, setMessageError] = useState<string>('')

  function renderIntegrationOptions(
    { integrationName }: IntegrationOptionsProps,
    index: number
  ) {
    return (
      <label htmlFor={`integration${index}`} className="containerIntegration">
        {integrationName}
        <Field
          type="radio"
          id={`integration${index}`}
          name="integradora"
          className="IntegrationInput"
          value={integrationName}
        />
      </label>
    )
  }

  return (
    <>
      <Formik
        initialValues={{ integradora: '' }}
        validate={(values: any) => {
          const errors: any = {}

          if (!values.integradora) {
            setMessageError(
              'Por favor, selecione uma das opções abaixo para continuar.'
            )
            setMessageErrorReport(
              'Por favor, selecione uma das opções abaixo para continuar.'
            )
            errors.integradora =
              'Por favor, selecione uma das opções abaixo para continuar.'
          } else if (values.integradora !== 'Outra Plataforma') {
            setShowAnotherOption(false)
            setMessageError('')
            setMessageErrorReport('')
          } else if (values.integradora === 'Outra Plataforma') {
            setShowAnotherOption(true)
          }

          return errors
        }}
        onSubmit={(values: any) => {
          if (anotherOptionText && values.integradora === 'Outra Plataforma') {
            setStorage('integradora', anotherOptionText)
            window.location.href = '/marketplace/cadastro'
          } else if (
            !anotherOptionText &&
            values.integradora === 'Outra Plataforma'
          ) {
            setMessageError(
              'Por favor, Insira o nome da sua integradora para continuar'
            )
            setMessageErrorReport(
              'Por favor, selecione uma das opções abaixo para continuar.'
            )
          } else if (values.integradora !== 'Outra Plataforma') {
            setStorage('integradora', values.integradora)
            window.location.href = '/marketplace/cadastro'
          }
        }}
      >
        {({ handleSubmit }: /* and other goodies */ any) => (
          <>
            <Form onSubmit={handleSubmit} className="containerSubForm">
              <section>
                {messageError ? <p className="erros">{messageError}</p> : null}
                <section className="containerRenderIntegrations">
                  {data.map(renderIntegrationOptions)}
                  {showAnotherOption ? (
                    <input
                      type="text"
                      className="IntegrationInput inputText"
                      placeholder="Descreva aqui o nome da sua integradora"
                      onChange={({ target }: any) =>
                        setAnotherOptionText(target.value)
                      }
                      value={anotherOptionText}
                    />
                  ) : null}
                </section>
              </section>
              <button type="submit" className="buttonSubmitForm">
                CONTINUAR
              </button>
            </Form>
          </>
        )}
      </Formik>
      {showModal ? (
        <ModelModal
          title={contentModal?.title}
          description={contentModal?.description}
          setShowModal={setShowModal}
        />
      ) : null}
    </>
  )
}
